import $ from 'cash-dom'

$(function () {
    // Accordion

    setTimeout(function() {
        $('.accordion').each(function(index, acc) {
            const content = $(acc).find('.accordion-content')
            const scrollHeight = content[0].scrollHeight + 56
            $(content).css('--max-height',scrollHeight+"px")
        })
    },1000)

    // Member Accordion

    $('.member-caret').on('click', function() {
        const input = $(this).parent().find('input')[0]
        input.checked = !input.checked;
        $(input).trigger('change')
    })

    $('.accordion input').on('change', function() {
        const caret = $(this).closest('.member-content-wrapper').find('.member-caret')
        if (caret.length > 0) $(caret).toggleClass('expanded')
    })

    // Mobile Hamburger Menu

    const hideMenu = function() {
        $('#main-menu').removeClass('visible')
        $('body').off('click')
    }

    const showMenu = function() {
        $('#main-menu').addClass('visible')
        $('body').on('click', function(e) {
            if ($(e.target).parents('#hamburger').length > 0) return
            if ($(e.target).parents('#main-menu').length > 0) return
            e.preventDefault();
            hideMenu()
        })
    }

    $('#hamburger').on('click', function() {
        if (!$('#main-menu').hasClass('visible')) {
            showMenu()
        } else {
            hideMenu()
        }
    })

    // Mobile Project Menu

    if ($('main.project').length > 0) {
        const projectSlug = $('main').attr('id')
        const navItem = $('#project-nav nav li#'+projectSlug)
        const itemX = navItem.offset().left - 16
        $('#project-nav nav')[0].scrollTo(itemX,0)
    }
})